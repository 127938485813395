<template>
  <!--服务配置 模块-->
  <div>
    <el-card shadow="never">
      <div class="where">
        <el-form
            :model="where"
            label-width="90px"
            class="ele-form-search"
            @submit.native.prevent>
          <el-row :gutter="15">
            <el-col :lg="21" :md="12">
              <el-form-item label="服务商:">
                <el-select
                    style="width: 300px !important;"
                    clearable
                    v-model="where.gender"
                    placeholder="请选择服务商"
                    class="ele-fluid">
                  <el-option label="服务商1" value="1"/>
                  <el-option label="服务商1" value="2"/>
                  <el-option label="服务商 1" value="3"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!--时效配置-->
      <div class="ageing">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              时效配置
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="Showageing()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-bianji" />
                <span style="vertical-align: middle" >编辑</span>
              </el-button>
            </div>
          </div>
        </div>

        <div class="ageing_b">
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">平台接单提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">客服响应提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">平台派单提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
          </el-row>
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">司机接单提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">司机接单至出发提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">司机出发至到达提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
          </el-row>
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">司机到达至完成提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">司机到达至回访提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">下单至司机接单提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
          </el-row>
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">下单至司机出发提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">下单至司机到达提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">下单至完成提醒：</span>
              <span class="ageing_b_text_r">20秒，考核：50秒</span>
            </el-col>
          </el-row>
        </div>
      </div>

      <!--水印配置-->
      <div class="ageing" style="margin-top: 20px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              水印配置
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="WatermarkConfiguration()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-bianji" />
                <span style="vertical-align: middle" >编辑</span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="ageing_b">
          <el-row style="margin: 15px;">
            <el-col :span="12" style="text-align: center">
              <div class="parallel">
                <div class="left">
                  内容：
                </div>
                <div class="right">
                  <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="救援师傅">
                      <el-input v-model="form.name" placeholder="请输入救援师傅姓名" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="经纬度">
                      <el-input v-model="form.name" placeholder="请输入经纬度" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="上传时间">
                      <el-input v-model="form.name" placeholder="请输入上传时间" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="地址">
                      <el-input v-model="form.name" placeholder="请输入地址" clearable></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </el-col>
            <el-col :span="12" style="text-align: center">
              <div class="parallel">
                <div class="left">
                  示例图：
                </div>
                <div class="right">
                  <div class="right_img" :style="{ backgroundImage: `url(${img})` }" >
                    <span style="color: #FF0707; font-size: 12px; text-align: left">2023/01/09  12:16  罗建军  上板照片广东省佛山市南海区招商悦府(建设中)lng:113.039883lat:2.896912</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!--照片模块配置-->
      <div class="ageing" style="margin-top: 20px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              照片模块配置
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="photoTemplate()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-bianji" />
                <span style="vertical-align: middle" >编辑</span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="ageing_b">
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">拖车：</span>
              <span class="ageing_b_text_r">拖车专用模板</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">搭电：</span>
              <span class="ageing_b_text_r">搭电专用模板</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">换胎：</span>
              <span class="ageing_b_text_r">中华换胎专用</span>
            </el-col>
          </el-row>
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">困境：</span>
              <span class="ageing_b_text_r">中华困境专用</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">充气：</span>
              <span class="ageing_b_text_r">默认模板</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">送油：</span>
              <span class="ageing_b_text_r">默认模板</span>
            </el-col>
          </el-row>
        </div>
      </div>

      <!--派单模块配置-->
      <div class="ageing" style="margin-top: 20px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              派单模块配置
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="DispatchModule()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-bianji" />
                <span style="vertical-align: middle" >编辑</span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="ageing_b">
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">接单模式：</span>
              <span class="ageing_b_text_r">API/人工/综合</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">派单模式：</span>
              <span class="ageing_b_text_r">抢单</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">拖车免拖里程：</span>
              <span class="ageing_b_text_r">50KM</span>
            </el-col>
          </el-row>
          <el-row style="margin: 15px;">
            <el-col :span="3" style="text-align: center">
              <span style="font-size: 16px;font-weight: 400;text-align: right;color: #868792;">派单注意事项：</span>
            </el-col>
            <el-col :span="21">
              <span style="font-size: 16px;font-weight: 500;text-align: left;color: #050505;">
                这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项
              </span>
            </el-col>
          </el-row>
          <el-row style="margin: 15px;">
            <el-col :span="3" style="text-align: center">
              <span style="font-size: 16px;font-weight: 400;text-align: right;color: #868792;">接单服务说明：</span>
            </el-col>
            <el-col :span="21">
              <span style="font-size: 16px;font-weight: 500;text-align: left;color: #050505;">
                这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项这是一条派单注意事项
              </span>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>

    <!-- 时效编辑弹窗 -->
    <ageing-edit :data="current" :visible.sync="showEdit"/>
    <!-- 照片模板配置 -->
    <photoTemplate-edit :data="photoTemplatecurrent" :visible.sync="showphotoTemplate"/>
    <!--派单模块配置-->
    <dispatchModule-edit :data="dispatchModulecurrent" :visible.sync="showdispatchModule"/>
    <!--水印配置-->
    <WatermarkConfigurationEdit :data="watermarkConfigurationcurrent" :visible.sync="showwatermarkConfiguration"/>


  </div>
</template>

<script>
  // 引入时效设置弹窗
  import AgeingEdit from './components/ageing-edit.vue';
  //引入照片模板弹窗
  import PhotoTemplateEdit from './components/photoTemplate-edit.vue';
  //引入派单模块配置
  import DispatchModuleEdit from './components/dispatchModule-edit.vue';
  //引入水印配置
  import WatermarkConfigurationEdit from './components/watermarkConfiguration-edit.vue'


  export default {
    components:{
      AgeingEdit,
      PhotoTemplateEdit,
      DispatchModuleEdit,
      WatermarkConfigurationEdit
    },
    data(){
      return{
        //条件
        where:{},
        // 水印配置表单数据
        form:{
          name:'',
        },

        // 示例图
        img: require('../../../../../assets/images/home/jine.png'),

        // 时效数据
        current: null,
        // 是否显示时效弹窗
        showEdit: false,

        //照片模板配置
        photoTemplatecurrent:null,
        showphotoTemplate:false,

        //派单配置
        dispatchModulecurrent:null,
        showdispatchModule:false,

        //水印配置
        watermarkConfigurationcurrent:null,
        showwatermarkConfiguration:false,

      }
    },

    methods:{
      // 点击时效配置
      Showageing(){
        this.current = this.form;
        this.showEdit = true;
      },

      //照片模板配置
      photoTemplate(){
        this.showphotoTemplate = true;
      },

      //派单配置
      DispatchModule(){
        this.showdispatchModule = true;
      },

      //水印配置
      WatermarkConfiguration(){
        this.showwatermarkConfiguration = true;
      },


    }

  }
</script>

<style lang="scss" scoped>
  .ageing{
    .ageing_b{
      padding-top: 15px;
      padding-bottom: 15px;
      background: #ffffff;
      border: 1px solid #e9eaf1;
      border-radius: 6px;
      margin-top: 15px;
    }

    .ageing_b_text_l{
      display: inline-block;
      width: 160px;
      font-size: 16px;
      font-weight: 400;
      color: #868792;
      text-align: right;
    }
    .ageing_b_text_r{
      margin-left: 15px;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #050505;
    }
  }

  // 左右并行
  .parallel:after{
    content: '';
    clear: both;
    display: block;
  }
  .parallel{
    .left{
      width: 30%;
      float: left;
      font-size: 18px;
      font-weight: 700;
      color: #050505;
      margin-top: 5px;
    }
    .right{
      width: 50%;
      float: left;
      margin-left: 15px;
    }
  }

  .right_img{
    width: 300px;
    height: 280px;
    padding: 10px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }


</style>

<!-- 时效设置弹窗 -->
<template>
  <el-dialog
    width="60%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'水印配置':'水印配置'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="120px">

      <el-row :gutter="15">
        <el-col :span="12">
          <div style="display: flex;align-items: center;">
            <div style="display: flex;align-items: center; margin-right: 20px;">
              <span>选择字体颜色：</span>
              <input type="color" v-model="color" style="border: none;">
            </div>
            <div>
              <span>字体加粗：</span>
              <span style="cursor: pointer; display: inline-block;width: 20px;text-align: center;" :style="{backgroundColor:bgcolor}" @click="jiacu">B</span>
            </div>
          </div>
          <div class="list">
            <!--<div v-for="(item,index) in list" :key="index" class="list_active">-->
            <!--  <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>-->
            <!--  <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>-->
            <!--</div>-->

            <div>第一行</div>
            <div style="padding: 10px;">
              <draggable v-model="list" group="people" @change="log">
                <template>
                  <div v-for="(item,index) in list" :key=" index" class="list_active">
                    <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
                    <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>
                  </div>
                </template>
              </draggable>
            </div>

            <div>第二行</div>
            <div style="padding: 10px;">
              <draggable v-model="list1" group="people" @change="log">
                <template style="padding: 10px;">
                  <!--<div style="display: flex; padding: 10px;">-->
                  <!--  -->
                  <!--</div>-->
                  <div v-for="(item,index) in list1" :key="index" class="list_active">
                    <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
                    <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>
                  </div>
                </template>
              </draggable>
            </div>

            <div>第三行</div>
            <div style="padding: 10px;">
              <draggable v-model="list2" group="people" @change="log">
                <template>
                  <!--<div style="display: flex;padding: 10px;">-->
                  <!--  -->
                  <!--</div>-->
                  <div v-for="(item,index) in list2" :key="index" class="list_active">
                    <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
                    <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>
                  </div>
                </template>
              </draggable>
            </div>

            <div>第四行</div>
            <div style="padding: 10px;">
              <draggable v-model="list3" group="people" @change="log">
                <template>
                  <!--<div style="display: flex;padding: 10px;">-->
                  <!--  -->
                  <!--</div>-->
                  <div v-for="(item,index) in list3" :key="index" class="list_active">
                    <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
                    <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>
                  </div>
                </template>
              </draggable>
            </div>

            <div>第五行</div>
            <div style="padding: 10px;">
              <draggable v-model="list4" group="people" @change="log">
                <template>
                  <!--<div style="display: flex;padding: 10px;">-->
                  <!--  -->
                  <!--</div>-->
                  <div v-for="(item,index) in list4" :key="index" class="list_active">
                    <span style="font-size: 14px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
                    <i class="el-icon-close" style="color: #ff9b05; font-weight: bold;margin-left: 15px;" @click="del(item,index)"></i>
                  </div>
                </template>
              </draggable>
            </div>

          </div>
        </el-col>
        <el-col :span="12">
          <div class="bg_img" :style="{ backgroundImage: `url(${img})` }">
            <div>
              <span  v-for="(item,index) in list" :key="index" style="font-size: 14px; display: inline-block; margin: 10px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
            </div>
            <div>
              <span  v-for="(item,index) in list1" :key="index" style="font-size: 14px;display: inline-block; margin: 10px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
            </div>
            <div>
              <span  v-for="(item,index) in list2" :key="index" style="font-size: 14px;display: inline-block; margin: 10px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
            </div>
            <div>
              <span  v-for="(item,index) in list3" :key="index" style="font-size: 14px;display: inline-block; margin: 10px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
            </div>
            <div>
              <span  v-for="(item,index) in list4" :key="index" style="font-size: 14px;display: inline-block; margin: 10px;" :style="{color: color,fontWeight:weigt}">{{item.label}}</span>
            </div>
          </div>
        </el-col>

      </el-row>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    draggable
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      img:require('../../../../../../assets/images/home/jine.png'),

      showCode:false,
      color:'#ff9b05',
      weigt:'',
      bgcolor:'',


      list:[
        {
          label:'拍摄时间'
        },
        {
          label:'司机时间'
        },
        {
          label:'图片名称'
        },
        {
          label:'拍摄地址'
        },
        {
          label:'经纬度'
        }
      ],
      list1:[
      ],
      list2:[
      ],
      list3:[
      ],
      list4:[
      ],

      DataList:[

      ],

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //删除
    del(item,index){
      console.log(item);
      // 执行一些需要的逻辑
      this.list.splice(index, 1)
    },

    //加粗
    jiacu(){
      this.showCode = !this.showCode;
      //改变样式
      if(this.showCode == true){
        this.bgcolor = '#e9eaeb';
        this.weigt = 'bold';
      }else {
        this.bgcolor = '';
        this.weigt = '';
      }
    },

    add: function() {
      this.list.push({ name: "Juan" });
    },
    replace: function() {
      this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt) {
      window.console.log(evt);
      // if(this.list.length != 0){
      //   this.list.forEach(item => {
      //     this.DataList.push(item);
      //   })
      // }else if(this.list1.length != 0){
      //   this.list1.forEach(item => {
      //     this.DataList.push(item);
      //   })
      // }else if(this.list2.length != 0){
      //   this.list2.forEach(item => {
      //     this.DataList.push(item);
      //   })
      // }else if(this.list3.length != 0){
      //   this.list3.forEach(item => {
      //     this.DataList.push(item);
      //   })
      // }else if(this.list4.length != 0){
      //   this.list4.forEach(item => {
      //     this.DataList.push(item);
      //   })
      // }

    }


  }
}
</script>

<style scoped lang="scss">
.bg_img{
  height: 460px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 20px;
}
.list{
  .list_active{
    width: 112px;
    text-align: center;
    background: #FFF5E5;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px;
    cursor: pointer;
    display: inline-block;
  }
}

.list_active1{
  width: 112px;
  text-align: center;
  background: #FFF5E5;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px;
  cursor: pointer;
}
</style>

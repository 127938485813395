import { render, staticRenderFns } from "./dispatchModule-edit.vue?vue&type=template&id=5b06b032&scoped=true&"
import script from "./dispatchModule-edit.vue?vue&type=script&lang=js&"
export * from "./dispatchModule-edit.vue?vue&type=script&lang=js&"
import style0 from "./dispatchModule-edit.vue?vue&type=style&index=0&id=5b06b032&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b06b032",
  null
  
)

export default component.exports